import React, { useState } from 'react'

import './PlanConstructorItem.css'

import { channelNames } from '../../../utils/constants'
import { channels } from '../utils/constants'

import Select from '../../../components/formControls/Select'
import QtySelect from '../../../components/formControls/QtySelect'

const PlanConstructorItem = ({ planConstructorItems, item, disableDeleteBtn, onChannelSelect, onQtyChange, onDelete }) => {
   const [selectedChannel, setSelectedChannel] = useState(item.selectedChannel)

   const { id, qty } = item

   const handleSelectChannel = (channel) => {
      setSelectedChannel(channel)
      onChannelSelect(channel, id)
   }

   const handleQtyChange = (qty) => {
      onQtyChange(qty, id)
   }

   const handleItemDelete = () => {
      onDelete(id)
   }

   return (
      <div className='plan-constructor-item'>
         <div className='plan-constructor-item__select'>
            <Select
               label='Тип канала'
               selectedValue={selectedChannel}
               values={planConstructorItems.length < 2
                     ? channels
                     : selectedChannel.name === channelNames.WHATSAPP
                        ? [channels[0]] 
                        : [channels[1]]
                  }
               onSelect={handleSelectChannel}
            />
         </div>
         <div className='plan-constructor-item__qty-select'>
            <QtySelect
               quantity={qty}
               onChange={handleQtyChange}
            />
         </div>
         <button 
            className='plan-constructor-item__delete-btn'
            type='button'
            onClick={handleItemDelete}
            disabled={disableDeleteBtn} 
         >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M15 6.75L13.5037 15.2597C13.3777 15.977 12.7546 16.5 12.0265 16.5H5.97355C5.24534 16.5 4.62232 15.977 4.49622 15.2597L3 6.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               <path d="M15.75 4.5H11.5312M11.5312 4.5V3C11.5312 2.17157 10.8597 1.5 10.0312 1.5H7.96875C7.14032 1.5 6.46875 2.17157 6.46875 3V4.5M11.5312 4.5H6.46875M2.25 4.5H6.46875" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
         </button>
      </div>
   )
}

export default PlanConstructorItem