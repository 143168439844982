import React from 'react'
import { Link } from 'react-router-dom'

import './AddChannelPage.css'

import routeNames from '../../router/routeNames'

import Page from '../../components/layouts/Page'

const AddChannelPage = () => {
   return (
      <Page
         title='Добавить канал'
         returnBtn
      >
         <div className='add-channel-page-body'>
            <Link
               className='add-channel-page-link add-channel-page-link_whatsapp'
               to={routeNames.MESSENGERS_ADD_WHATSAPP}
            >
               <img src='/assets/icons/add-channel-whatsapp-icon.svg' alt='' />
               WhatsApp
            </Link>
            <Link
               className='add-channel-page-link add-channel-page-link_telegram'
               to={routeNames.MESSENGERS_ADD + '/telegram'}
            >
               <img src='/assets/icons/add-channel-telegram-icon.svg' alt='' />
               Telegram
            </Link>
         </div>
      </Page>
   )
}

export default AddChannelPage