import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './MessengersPage.css'

import { defaultModalData, messengersPageModals } from './utils/constants'
import routeNames from '../../router/routeNames'
import { channels_selectChannels, channels_selectChannelsLoading } from '../../store/slices/channels/selectors'
import { 
   activateChannel, 
   activateChannelWithData, 
   createChannel, 
   createChannelWithCode, 
   createChannelWithPassword, 
   deactivateChannel, 
   deleteChannel, 
   fetchChannels 
} from '../../store/slices/channels/thunk-creators'

import Page from '../../components/layouts/Page'
import Button from '../../components/buttons/Button'
import ChannelsList from './ChannelsList'
import EmptyListMessage from '../../components/layouts/EmptyListMessage'
import DeleteChannelModal from '../../components/modals/DeleteChannelModal'
import AddChannelNumberModal from '../../components/modals/AddChannelNumberModal'
import AddChannelCodeModal from '../../components/modals/AddChannelCodeModal'
import AddChannelPasswordModal from '../../components/modals/AddChannelPasswordModal'
import ActivateChannelCodeModal from '../../components/modals/ActivateChannelCodeModal'
import ActivateChannelPasswordModal from '../../components/modals/ActivateChannelPasswordModal'
import Loader from '../../components/layouts/Loader'


const MessengersPage = () => {
   const { channel } = useParams()

   const channelsLoading = useSelector(channels_selectChannelsLoading)
   const channels = useSelector(channels_selectChannels)

   const [showModal, setShowModal] = useState(defaultModalData)
   const [channelIdToDelete, setChannelIdToDelete] = useState(null)

   const showNumberModal = showModal.name === messengersPageModals.number
   const showCodeModal = showModal.name === messengersPageModals.code
   const showActivateChannelCodeModal = showModal.name === messengersPageModals.activateChannelCode
   const showActivateChannelPasswordModal = showModal.name === messengersPageModals.activateChannelPassword
   const showPasswordModal = showModal.name === messengersPageModals.password
   const showDeleteChannelModal = showModal.name === messengersPageModals.deleteChannel

   const { pathname } = useLocation()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const handleFetchChannels = () => {
      dispatch(fetchChannels())
   }

   const handleChannelStatusChange = async (data, isActive) => {
      try {
         const action = isActive ? deactivateChannel : activateChannel
         await dispatch(action(data))  
      } catch (data) {
         handleOpenModal({
            name: messengersPageModals.activateChannelCode,
            data
         })
      }
   }

   const handleOpenModal = (modal) => setShowModal(modal)

   const handleCloseModal = () => {
      navigate(routeNames.MESSENGERS)
      setShowModal(defaultModalData)
   }

   const handleDeleteBtnClick = (id) => {
      setChannelIdToDelete(id)
      handleOpenModal({
         name: messengersPageModals.deleteChannel
      })
   }

   const handleChannelDelete = async (id) => {
      await dispatch(deleteChannel(id))
      handleCloseModal()
   }

   const handleAddChannelNumberModalSubmit = async ({ phone }) => {
      try {
         const data = await dispatch(createChannel({
            channel: 'telegram',
            phone: '+' + phone
         }))
         handleOpenModal({
            name: messengersPageModals.code,
            data
         })
      } catch (err) {
         if (err) return Promise.reject(err)
      }
   }

   const handleAddChannelCodeModalSubmit = async (data) => {
      try {
         await dispatch(createChannelWithCode(data))
         handleCloseModal()
      } catch (err) {
         if (err) return Promise.reject(err)

         handleOpenModal(modal => ({
            ...modal,
            name: messengersPageModals.password
         }))
      }
   }

   const handleAddChannelPasswordModalSubmit = async (data) => {
      try {
         await dispatch(createChannelWithPassword(data))
         handleCloseModal()
      } catch (err) {
         if (err) return Promise.reject(err)
      }
   }

   const handleActivateChannelCodeModalSubmit = async (data) => {
      try {
         await dispatch(activateChannelWithData(data))
         handleCloseModal()
      } catch (err) {
         if (err) return Promise.reject(err)

         handleOpenModal(modal => ({
            ...modal,
            name: messengersPageModals.password
         }))
      }
   }

   const handleActivateChannelPasswordModalSubmit = async (data) => {
      try {
         await dispatch(activateChannelWithData(data))
         handleCloseModal()
      } catch (err) {
         if (err) return Promise.reject(err)
      }
   }

   const handleAddChannelBtnClick = () => navigate(routeNames.MESSENGERS_ADD)

   useEffect(() => {
      if (channel === 'telegram') {
         setShowModal({
            name: messengersPageModals.number
         })
      }
   }, [channel])

   useEffect(() => {
      if (pathname === routeNames.MESSENGERS) handleFetchChannels()
   }, [pathname])

   if (channelsLoading) return <Loader />

   return (
      <Page title='Мессенджеры'>
         {channels.length > 0
            ? (<div className='messengers-page__body'>
                  <ChannelsList
                     channels={channels}
                     handleStatusChange={handleChannelStatusChange}
                     handleDeleteBtnClick={handleDeleteBtnClick}
                  />
                  <Button
                     className='messengers-page__add-channel-btn'
                     text='Добавить канал'
                     onClick={handleAddChannelBtnClick}
                  >
                     <img src='/assets/icons/plus-icon.svg' alt='Add channel' />
                  </Button>
               </div>
            ) : (
               <EmptyListMessage
                  imgSrc='/assets/images/channels-empty.png'
                  text='Вы еще не добавили ни одного мессенджера.'
                  btnText='Добавить канал'
                  onClick={handleAddChannelBtnClick}
               />
            )
         }
         <DeleteChannelModal
            channelIdToDelete={channelIdToDelete}
            isVisible={showDeleteChannelModal}
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            onConfirm={handleChannelDelete}
         />
         <AddChannelNumberModal
            isVisible={showNumberModal}
            onClose={handleCloseModal}
            onSubmit={handleAddChannelNumberModalSubmit}
         />
         <AddChannelCodeModal
            isVisible={showCodeModal}
            data={showModal.data}
            onClose={handleCloseModal}
            onSubmit={handleAddChannelCodeModalSubmit}
         />
         <AddChannelPasswordModal 
            isVisible={showPasswordModal}
            data={showModal.data}
            onClose={handleCloseModal}
            onSubmit={handleAddChannelPasswordModalSubmit}
         />
         <ActivateChannelCodeModal 
            isVisible={showActivateChannelCodeModal}
            data={showModal.data}
            onClose={handleCloseModal}
            onSubmit={handleActivateChannelCodeModalSubmit}
         />
         <ActivateChannelPasswordModal 
            isVisible={showActivateChannelPasswordModal}
            data={showModal.data}
            onClose={handleCloseModal}
            onSubmit={handleActivateChannelPasswordModalSubmit}
         />
      </Page>
   )
}

export default MessengersPage