import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   channelsLoading: false,
   channels: [],
   qrLoading: false,
   qrCode: '',
   sourceId: ''
}

const ChannelsStatuses = {
   ACTIVE: 1,
   INACTIVE: 0
}

export const channelsSlice = createSlice({
   name: 'channels',
   initialState,
   reducers: {
      fetchChannelsStarted: (state) => {
         state.channelsLoading = true
      },
      fetchChannelsSuccess: (state, action) => {
         state.channels = [ ...action.payload ]
      },
      fetchChannelsFinished: (state) => {
         state.channelsLoading = false
      },
      fetchWhatsappQrStarted: (state) => {
         state.qrLoading = true
         state.qrCode = ''
         state.sourceId = ''
      },
      fetchWhatsappQrSuccess: (state, action) => {
         state.qrCode = action.payload.qrCode
         state.sourceId = action.payload.sourceId
      },
      fetchWhatsappQrFinished: (state) => {
         state.qrLoading = false
      },
      clearWhatsappChannelData: (state) => {
         state.qrCode = ''
         state.sourceId = ''
      },
      activateChannelSuccess: (state, action) => {
         state.channels = state.channels.map(channel => (
            channel.id === action.payload
               ? { ...channel, status: { id: ChannelsStatuses.ACTIVE } }
               : channel
         ))
      },
      deactivateChannelSuccess: (state, action) => {
         state.channels = state.channels.map(channel => (
            channel.id === action.payload
               ? { ...channel, status: { id: ChannelsStatuses.INACTIVE } }
               : channel
         ))
      },
      deleteChannelSuccess: (state, action) => {
         state.channels = state.channels.filter(channel => channel.id !== action.payload)
      }
   }
})

export const { 
   fetchChannelsStarted, 
   fetchChannelsSuccess,
   fetchChannelsFinished,
   fetchWhatsappQrStarted,
   fetchWhatsappQrSuccess,
   fetchWhatsappQrFinished,
   clearWhatsappChannelData,
   activateChannelSuccess,
   deactivateChannelSuccess,
   deleteChannelSuccess
} = channelsSlice.actions
export default channelsSlice.reducer