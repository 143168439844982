import { channelNames } from "../../../utils/constants"

export const periodVariants = [
   {
      id: 1,
      name: '1 (30 дней)',
      value: 1
   },
   {
      id: 2,
      name: '2 (60 дней)',
      value: 2
   },
   {
      id: 3,
      name: '3 (90 дней)',
      value: 3
   },
   {
      id: 4,
      name: '4 (120 дней)',
      value: 4
   },
   {
      id: 5,
      name: '5 (150 дней)',
      value: 5
   },
   {
      id: 6,
      name: '6 (180 дней)',
      value: 6
   },
   {
      id: 7,
      name: '7 (210 дней)',
      value: 7
   },
   {
      id: 8,
      name: '8 (240 дней)',
      value: 8
   },
   {
      id: 9,
      name: '9 (270 дней)',
      value: 9
   },
   {
      id: 10,
      name: '10 (300 дней)',
      value: 10
   },
   {
      id: 11,
      name: '11 (330 дней)',
      value: 11
   },
   {
      id: 12,
      name: '12 (360 дней)',
      value: 12
   },
   {
      id: 13,
      name: '13 (390 дней)',
      value: 13
   },
   {
      id: 14,
      name: '14 (420 дней)',
      value: 14
   },
   {
      id: 15,
      name: '15 (450 дней)',
      value: 15
   },
   {
      id: 16,
      name: '16 (480 дней)',
      value: 16
   },   
   {
      id: 17,
      name: '17 (510 дней)',
      value: 17
   },
   {
      id: 18,
      name: '18 (540 дней)',
      value: 18
   },
   {
      id: 19,
      name: '19 (570 дней)',
      value: 19
   },
   {
      id: 20,
      name: '20 (600 дней)',
      value: 20
   },
   {
      id: 21,
      name: '21 (630 дней)',
      value: 21
   },
   {
      id: 22,
      name: '22 (660 дней)',
      value: 22
   },
   {
      id: 23,
      name: '23 (690 дней)',
      value: 23
   },
   {
      id: 24,
      name: '24 (720 дней)',
      value: 24
   }
]

export const channels = [
   {
      id: 1,
      name: channelNames.WHATSAPP
   },
   {
      id: 2,
      name: channelNames.TELEGRAM
   }
]

export const planConstructorItemStructure = {
   id: 1,
   selectedChannel: {
      id: 1,
      name: 'WhatsApp'
   },
   qty: 1
}

export const channelNamesMap = {
   amo_channel: 'amoCrm',
   bitrix_channel: 'Битрикс24',
   whatsapp_channel_test: 'WhatsApp',
   whatsapp_channel: 'WhatsApp',
   telegram_channel_test: 'Telegram',
   telegram_channel: 'Telegram'
}