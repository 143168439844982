import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './PlanPage.css'

import { auth_selectUser } from '../../store/slices/auth/selectors'
import { checkAuth } from '../../store/slices/auth/thunk-creators'
import { 
   calculatePlanPrice, 
   changeTariff, 
   createPayment, 
   redirectToPaymentPage, 
   updateAutoPay } from '../../store/slices/plan/thunk-creators'
import { getFormattedDate, getIsPlanActive } from './utils'

import Page from '../../components/layouts/Page'
import Toggle from '../../components/formControls/Toggle'
import PlanConstructor from './PlanConstructor'
import Loader from '../../components/layouts/Loader'
import debounce from 'lodash.debounce'

const PlanPage = () => {
   const { autoPay, orders } = useSelector(auth_selectUser)

   const currentOrder = orders[orders.length - 1]
   const { niceName, tariffPlanMapEntries } = currentOrder?.plan
   const planEndDate = getFormattedDate(currentOrder.planEndAt)
   const planPrice = currentOrder.price
   const isPlanActive = getIsPlanActive(currentOrder.planEndAt, planPrice)
   
   const [isLoading, setIsLoading] = useState(false)
   const [disableAutoSubscriptionToggle, setDisableAutoSubscriptionToggle] = useState(!autoPay)
   const [isCalculating, setIsCalculating] = useState(!isPlanActive)
   const [calculatedPrice, setCalculatedPrice] = useState(0)

   const dispatch = useDispatch()

   const handleAutoPayChange = async () => {
      setDisableAutoSubscriptionToggle(true)
      await dispatch(updateAutoPay({ autoPay: !autoPay }))
   }

   const handleConstructorSubmit = async (orderData) => {
      try {
         if (!isPlanActive) {
            const data = await dispatch(createPayment(orderData))
            await dispatch(redirectToPaymentPage(data.confirmationUrl))
         } else {
            await dispatch(changeTariff(orderData))
            setIsLoading(true)
            await dispatch(checkAuth())
         }
      } catch (err) {
         
      } finally {
         setIsLoading(false)
      }
   }

   const handleCalculatePlanPrice = useCallback(debounce(async (data) => {
      setIsCalculating(true)
      const price = await dispatch(calculatePlanPrice(data))
      setCalculatedPrice(price)
      setIsCalculating(false)
   }, 1000), [])

   if (isLoading) return <Loader />

   return (
      <Page
         title='Покупка тарифа'
      >
         <div className='plan-page-body'>
            <div className='plan-page-current-plan'>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Текущий тариф
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     {niceName || 'Кастомный тариф'}
                  </div>
               </div>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Срок окончания тарифа
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     {planEndDate}
                  </div>
               </div>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Автоматическая подписка
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     <Toggle
                        checked={autoPay}
                        disabled={disableAutoSubscriptionToggle}
                        onChange={handleAutoPayChange}
                     />
                  </div>
               </div>
            </div>
            <PlanConstructor
               isPlanActive={isPlanActive}
               planItems={tariffPlanMapEntries}
               autoPay={autoPay}
               planPrice={planPrice}
               isCalculating={isCalculating}
               calculatedPrice={calculatedPrice}
               onPlanChange={handleCalculatePlanPrice}
               onConstructorSubmit={handleConstructorSubmit}
            />
         </div>
      </Page>
   )
}

export default PlanPage