import { combineReducers, configureStore } from '@reduxjs/toolkit'
import auth from './slices/auth'
import channels from './slices/channels'
import crms from './slices/crms'

const rootReducer = combineReducers({
   auth,
   channels,
   crms
})

const store = configureStore({
   reducer: rootReducer
})

export default store