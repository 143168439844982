import { channelNamesMap } from "./constants"

export const getNoramlizedPlanItems = (items) => {
   const filteredItems = items.filter(item => item.tariff.name !== 'amo_channel' && item.tariff.name !== 'bitrix_channel')
   return filteredItems.map(item => ({
      id: item.id,
      selectedChannel: {
         id: item.tariff.id,
         name: channelNamesMap[item.tariff.name]
      },
      qty: item.quantity
   }))
}

export const getChannelQty = (items, channelName) => {
   return items.reduce((acc, item) => (
      item.selectedChannel.name === channelName
         ? acc + item.qty
         : acc
   ), 0)
}

export const getFormattedDate = (dateString) => {
   const date = new Date(dateString)
   const day = date.getDate()
   const month = date.getMonth() + 1
   const year = date.getFullYear()
   return `${day}.${month < 10 ? `0${month}` : month}.${year}`
}

export const getIsPlanActive = (planEndDate, planPrice) => {
   const endDate = new Date(planEndDate)
   const currentDate = new Date()
   
   return Boolean(endDate > currentDate && planPrice !== 0)
}