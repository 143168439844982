import PlanService from "../../../services/planService"
import { changeTariffSuccess, updateUserSuccess } from "../auth"

export const updateAutoPay = (body) => async (dispatch) => {
   try {
      await PlanService.updateAutoPay(body)
      dispatch(updateUserSuccess(body))
   } catch (err) {
      console.log(err)
   }
}

export const createPayment = (body) => async (dispatch) => {
   try {
      const data = await PlanService.createPayment(body)
      if (data.data) return data.data
   } catch (err) {
      console.log(err)
   }
}

export const redirectToPaymentPage = (body) => async (dispatch) => {
   try {
      await PlanService.redirectToPaymentPage(body)
   } catch (err) {
      console.log(err)
   }
}

export const changeTariff = (body) => async (dispatch) => {
   try {
      await PlanService.changePayment(body)
   } catch (err) {
      console.log(err)
   }
}

export const calculatePlanPrice = (body) => async (dispatch) => {
   try {
      const data = await PlanService.calculatePrice(body)
      if (data.price !== undefined) return data.price
   } catch (err) {
      console.log(err)
   }
}