import $axios from "../http"

class PlanService {
   static updateAutoPay = async (body) => {
      const { data } = await $axios.patch('user/profile', body)
      return data
   }

   static createPayment = async (body) => {
      const { data } = await $axios.post('payment-create', body)
      return data
   }

   static redirectToPaymentPage = async (body) => {
      window.open(`${process.env.REACT_APP_SERVER_URL}payment-redirect?confirmationUrl=${body}`)
   }

   static changePayment = async (body) => {
      const { data } = await $axios.patch('payment-change', body)
      return data
   }

   static calculatePrice = async (body) => {
      const { data } = await $axios.post('payment-calculate', body)
      return data
   }
}

export default PlanService